import { Image } from "semantic-ui-react";
import cx from "classnames";
import styles from "./CarrierTriangleBadge.module.scss";

type CarrierTriangleBadgeProps = {
  turvoId: string;
  statusCodeId: number | null;
};

const getColor = (statusCodeId: number | null): string => {
  if (statusCodeId === 36) {
    return styles.created;
  }

  if (statusCodeId === 37) {
    return styles.active;
  }

  return styles.inActive;
};

const getText = (statusCodeId: number | null): string => {
  if (statusCodeId === 36) {
    return "Created";
  }

  if (statusCodeId === 37) {
    return "Active";
  }

  return "Inactive";
};

const CarrierTriangleBadge = ({
  turvoId,
  statusCodeId,
}: CarrierTriangleBadgeProps) => {
  const srcUrl = "/assets/images/turvo-logo-mark.svg";

  return (
    <div className={styles.mainContainer}>
      {turvoId ? (
        <Image
          src={srcUrl}
          className={styles.topLogoImage}
          alt={"turvo-logo"}
        />
      ) : null}
      <div className={cx(styles.topTriangle, getColor(statusCodeId))}>
        <div className={styles.hoverTitleContainer}>
          {getText(statusCodeId)}
        </div>
      </div>
    </div>
  );
};

export default CarrierTriangleBadge;
