import { toast } from "react-toastify";

import CarrierCapacityTable from "./tables/CarrierCapacityTable/CarrierCapacityTable";
import CarrierPlanCapacityTable from "./tables/CarrierPlanCapacityTable/CarrierPlanCapacityTable";
import CarrierPreferredLaneTable from "./tables/CarrierPreferredLaneTable/CarrierPreferredLaneTable";
import { useGetEquipmentOptions } from "hooks/useGetEquipmentOptions";
import { useAppDispatch } from "redux/store/hooks";
import { fetchActivities } from "redux/activities/activitiesSlice";
import { postCarrierCapacityNote } from "./api/carrierCapacityApi";
import { SelectedCarrier } from "models/SelectedCarrier";

import styles from "./CarrierCapacity.module.scss";

type CarrierCapacityProps = {
  carrier: SelectedCarrier;
};

const CarrierCapacity = (props: CarrierCapacityProps) => {
  const dispatch = useAppDispatch();
  const { data: equipmentOptions } = useGetEquipmentOptions();

  const updateCarrierTimeline = async (note: string) => {
    try {
      await postCarrierCapacityNote({
        carrierId: props.carrier.id,
        note,
      });

      toast.success("Carrier timeline updated");
    } catch (error) {
      console.error({ error });
      toast.error("Error updating carrier timeline");
    }
  };

  const refreshCarrierTimeline = () => {
    dispatch(
      fetchActivities({
        page: 1,
        pageSize: 20,
        profileType: "CARRIER",
        profileId: props.carrier.id.toString(),
      })
    );
  };

  const childProps = {
    ...props,
    equipmentOptions,
    updateCarrierTimeline,
    refreshCarrierTimeline,
  };

  return (
    <div className={styles.carrierCapacity}>
      <CarrierCapacityTable {...childProps} />
      <CarrierPlanCapacityTable {...childProps} />
      <CarrierPreferredLaneTable {...childProps} />
    </div>
  );
};

export default CarrierCapacity;
