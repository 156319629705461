import { debounce } from "lodash";
import { RefObject, useEffect } from "react";

// This hook is used to detect when a container has been scrolled to the bottom
const useContainerScroll = ({
  containerRef,
  canScroll,
  scrollCallback,
}: {
  containerRef: RefObject<HTMLDivElement>;
  canScroll: boolean;
  scrollCallback: () => void;
}) => {
  const bufferPixels = 50;

  useEffect(() => {
    if (canScroll && containerRef.current) {
      const scrollListener = debounce((event) => {
        if (
          event.target.offsetHeight + event.target.scrollTop + bufferPixels >=
          event.target.scrollHeight
        ) {
          scrollCallback();
        }
      }, 100);

      const currentRef = containerRef.current;
      currentRef.addEventListener("scroll", scrollListener);
      return () => currentRef.removeEventListener("scroll", scrollListener);
    }
  }, [containerRef, canScroll, scrollCallback]);
};

export default useContainerScroll;
