import { Image, Label, SemanticCOLORS } from "semantic-ui-react";
import styles from "../CarrierList.module.scss";

const getColor = (status: number | null): SemanticCOLORS => {
  if (status === 36) {
    return "green";
  }

  if (status === 37) {
    return "blue";
  }

  return "grey";
};

const getText = (status: number | null): string => {
  if (status === 36) {
    return "Created";
  }

  if (status === 37) {
    return "Active";
  }

  return "Inactive";
};

type CarrierLabelBadgeProps = {
  turvoId: string;
  statusCodeId: number | null;
};

const CarrierLabelBadge = (props: CarrierLabelBadgeProps) => {
  return (
    <Label
      ribbon
      title={getText(props.statusCodeId)}
      color={getColor(props.statusCodeId)}
    >
      {props.turvoId ? (
        <Image
          src="/assets/images/turvo-logo-mark.svg"
          alt="turvo-logo"
          className={styles.turvoLogo}
        />
      ) : null}

      <div>{getText(props.statusCodeId)}</div>
    </Label>
  );
};

export default CarrierLabelBadge;
