import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import styled from "styled-components";

import { actions } from "redux/marketplace/marketplaceSlice";
import PageHeader from "components/Navigation/PageHeader";
import { MarketplaceCarrierTable } from "./MarketplaceCarrierTable";
import { inputDebounceDelay } from "hooks/constants";

const MarketplaceCarriers = () => {
  const dispatch = useDispatch();
  const { carrierList } = useSelector((state) => state.marketplace);
  const [filter, setFilter] = useState({
    search: "",
  });

  const debouncedFetch = useRef(
    debounce(
      (searchQuery) => dispatch(actions.fetchCarrierList(searchQuery)),
      inputDebounceDelay,
    )
  );

  useEffect(() => {
    if (filter.search?.length >= 3 || filter.search?.length === 0) {
      debouncedFetch.current({ search: filter.search });
    }
  }, [debouncedFetch, dispatch, filter.search]);

  const handleSearchChange = (value) => {
    setFilter({ ...filter, search: value });
  };

  return (
    <MarketplaceCarriersPageStyled>
      <PageHeader
        onSearch={handleSearchChange}
        searchValue={filter.search}
        searchTitle="Search by Name or Turvo Id ..."
      />
      <MarketplaceCarriersTableStyled>
        <MarketplaceCarrierTable
          loading={carrierList.loading}
          data={carrierList.data}
        />
      </MarketplaceCarriersTableStyled>
    </MarketplaceCarriersPageStyled>
  );
};

export default MarketplaceCarriers;

const MarketplaceCarriersPageStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MarketplaceCarriersTableStyled = styled.div`
  width: 100%;
`;
