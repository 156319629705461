import { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Button, Modal } from "semantic-ui-react";

import CarrierCapacityForm, {
  CarrierCapacityFormObject,
} from "./CarrierCapacityForm";
import { Option } from "models/Option";
import {
  CarrierCapacityInfoDto,
  CarrierCapacityTypeInfoDto,
} from "models/dto/CarrierCapacityInfoDto";
import { mapFormToApiObject } from "./utils/carrierCapacityFormToApiMappers";
import {
  postCarrierCapacity,
  putCarrierCapacity,
} from "../../api/carrierCapacityApi";
import { SelectedCarrier } from "models/SelectedCarrier";

type CarrierCapacityFormModalProps<T extends CarrierCapacityTypeInfoDto> = {
  carrier: SelectedCarrier;
  equipmentOptions: Option<number>[];
  onClose: () => void;
  defaultValues: CarrierCapacityFormObject;
  onSubmitCallback: () => void;
  originalCapacity?: CarrierCapacityInfoDto<T>;
};

const capacityTypeToTextMap: Record<CarrierCapacityTypeInfoDto, string> = {
  Capacity: "Capacity",
  NoCapacity: "No Capacity",
  "Preferred Lane": "Preferred Lane",
};

const CarrierCapacityFormModal = <T extends CarrierCapacityTypeInfoDto>(
  props: CarrierCapacityFormModalProps<T>
) => {
  const {
    control,
    errors,
    setValue,
    handleSubmit,
    register,
    watch,
    trigger,
    formState: { isSubmitted, isSubmitting },
  } = useForm<CarrierCapacityFormObject>({
    defaultValues: props.defaultValues,
  });

  register({ name: "id" });
  register({ name: "type" });
  const origin = watch("origin");
  const destination = watch("destination");

  // Since the requirement of the origin and destination fields for "no capacity" is dependent on origin or destination having a value,
  // re-trigger validation whenever hasOriginOrDestination changes to prevent showing errors when the field is cleared
  const { type } = props.defaultValues;
  const hasOriginOrDestination = !!origin.length || !!destination.length;
  useEffect(() => {
    if (isSubmitted && type === "NoCapacity") {
      trigger("origin");
      trigger("destination");
    }
  }, [isSubmitted, type, hasOriginOrDestination, trigger]);

  const onSubmit = async (formObject: CarrierCapacityFormObject) => {
    const apiObject = mapFormToApiObject({
      formObject,
      capacity: props.originalCapacity,
    });

    if (!apiObject) {
      toast.error(
        "There was an error in form validation.  Please check your entries and try again."
      );
    } else {
      try {
        apiObject.id
          ? await putCarrierCapacity({
              carrierId: props.carrier.id,
              data: apiObject,
            })
          : await postCarrierCapacity({
              carrierId: props.carrier.id,
              data: apiObject,
            });

        toast.success("Capacity saved successfully");

        props.onSubmitCallback();
        props.onClose();
      } catch (error) {
        console.error(error);
        toast.error("There was an error saving capacity");
      }
    }
  };

  return (
    <Modal size="large" open onClose={props.onClose}>
      <Modal.Header>
        {props.defaultValues.id ? "Edit" : "Add"}{" "}
        {capacityTypeToTextMap[props.defaultValues.type]} - {props.carrier.name}
      </Modal.Header>

      <Modal.Content>
        <CarrierCapacityForm
          control={control}
          errors={errors}
          setValue={setValue}
          closeForm={props.onClose}
          defaultValues={props.defaultValues}
          equipmentOptions={props.equipmentOptions}
          hasOriginOrDestination={hasOriginOrDestination}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button type="button" negative onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          loading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CarrierCapacityFormModal;
