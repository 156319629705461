import styles from "./searchbar.module.scss";
import { Icon } from "semantic-ui-react";
import {
  ChangeEvent,
  KeyboardEvent,
  LegacyRef,
  useCallback,
  useState,
} from "react";

type SearchBarProps = {
  inputPlaceholder: string;
  onSearch: ((value: string) => void) | undefined;
  autoTypeOfInputSearch: boolean | undefined;
  carrierSearch: boolean | undefined;
  inputRef: LegacyRef<HTMLInputElement> | undefined;
};

export const SearchBar = ({
  inputPlaceholder,
  autoTypeOfInputSearch,
  onSearch,
  carrierSearch,
  inputRef,
}: SearchBarProps) => {
  const [focusInput, setFocusInput] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const searchLoopStyle: string = autoTypeOfInputSearch
    ? styles.icon_wrapper_auto_search
    : styles.icon_wrapper;

  const disableSearchIcon = carrierSearch && !inputValue.length;

  const handleOnInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInputValue(value);
    },
    []
  );

  const handleSearchButtonClick = useCallback(() => {
    if (onSearch) {
      onSearch(inputValue);
    }
  }, [inputValue, onSearch]);

  const handleOnInputChangeAutoSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInputValue(value);
      if (onSearch) {
        onSearch(value);
      }
    },
    [onSearch]
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && onSearch) {
        onSearch(inputValue);
      }
    },
    [inputValue, onSearch]
  );

  return (
    <div className={styles.search_wrapper}>
      <input
        ref={inputRef}
        type="text"
        placeholder={inputPlaceholder}
        onFocus={() => setFocusInput(true)}
        onBlur={() => setFocusInput(false)}
        onChange={
          autoTypeOfInputSearch
            ? handleOnInputChangeAutoSearch
            : handleOnInputChange
        }
        onKeyDown={handleKeyPress}
        autoFocus
      />
      <div
        className={`${searchLoopStyle} ${focusInput ? styles.focus_input : ""}`}
        title="search-area"
      >
        <Icon
          name="search"
          className={disableSearchIcon ? styles.default_cursor : ""}
          onClick={disableSearchIcon ? null : handleSearchButtonClick}
          link
          title="search-icon"
        />
      </div>
    </div>
  );
};
