// Carrier Constants
export const FETCH_CARRIERS_START = 'FETCH_CARRIERS_START';
export const FETCH_CARRIERS_SEARCH = "FETCH_CARRIERS_SEARCH";
export const FETCH_CARRIERS_INVALIDATE = 'FETCH_CARRIERS_INVALIDATE';
export const FETCH_CARRIERS_SUCCESS = 'FETCH_CARRIERS_SUCCESS';
export const FETCH_CARRIERS_ERROR = 'FETCH_CARRIERS_ERROR';
export const RESET_CARRIERS = 'RESET_CARRIERS';

export const FETCH_SINGLE_CARRIER_START = 'FETCH_SINGLE_CARRIER_START';
export const FETCH_SINGLE_CARRIER_SUCCESS = 'FETCH_SINGLE_CARRIER_SUCCESS';
export const FETCH_SINGLE_CARRIER_ERROR = 'FETCH_SINGLE_CARRIER_ERROR';

export const FETCH_CARRIER_NOTES_SUCCESS = 'FETCH_CARRIER_NOTES_SUCCESS';

export const VALIDATE_CARRIER_START = 'VALIDATE_CARRIER_START';
export const VALIDATE_CARRIER_SUCCESS = 'VALIDATE_CARRIER_SUCCESS';
export const VALIDATE_CARRIER_ERROR = 'VALIDATE_CARRIER_ERROR';

export const CREATE_CARRIER_START = 'CREATE_CARRIER_START';
export const CREATE_CARRIER_SUCCESS = 'CREATE_CARRIER_SUCCESS';
export const CREATE_CARRIER_ERROR = 'CREATE_CARRIER_ERROR';

export const UPDATE_CARRIER_START = 'UPDATE_CARRIER_START';
export const UPDATE_CARRIER_SUCCESS = 'UPDATE_CARRIER_SUCCESS';
export const UPDATE_CARRIER_ERROR = 'UPDATE_CARRIER_ERROR';

export const UPLOAD_FROM_CSV_START = 'UPLOAD_FROM_CSV_START';
export const UPLOAD_FROM_CSV_SUCCESS = 'UPLOAD_FROM_CSV_SUCCESS';
export const UPLOAD_FROM_CSV_ERROR = 'UPLOAD_FROM_CSV_ERROR';

export const UPLOAD_IMAGE_START = 'UPLOAD_IMAGE_START';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';

export const DELETE_IMAGE_START = 'DELETE_IMAGE_START';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_ERROR = 'DELETE_IMAGE_ERROR';

// Carrier Contacts Constants
export const FETCH_CARRIER_CONTACTS_START = 'FETCH_CARRIER_CONTACTS_START';
export const FETCH_CARRIER_CONTACTS_SUCCESS = 'FETCH_CARRIER_CONTACTS_SUCCESS';
export const FETCH_CARRIER_CONTACTS_ERROR = 'FETCH_CARRIER_CONTACTS_ERROR';

export const CREATE_CARRIER_CONTACT_START = 'CREATE_CARRIER_CONTACT_START';
export const CREATE_CARRIER_CONTACT_SUCCESS = 'CREATE_CARRIER_CONTACT_SUCCESS';
export const CREATE_CARRIER_CONTACT_ERROR = 'CREATE_CARRIER_CONTACT_ERROR';

export const UPDATE_CARRIER_CONTACT_START = 'UPDATE_CARRIER_CONTACT_START';
export const UPDATE_CARRIER_CONTACT_SUCCESS = 'UPDATE_CARRIER_CONTACT_SUCCESS';
export const UPDATE_CARRIER_CONTACT_ERROR = 'UPDATE_CARRIER_CONTACT_ERROR';

export const DELETE_CARRIER_CONTACT_START = 'DELETE_CARRIER_CONTACT_START';
export const DELETE_CARRIER_CONTACT_SUCCESS = 'DELETE_CARRIER_CONTACT_SUCCESS';
export const DELETE_CARRIER_CONTACT_ERROR = 'DELETE_CARRIER_CONTACT_ERROR';

// Carrier Contact Addresses Constants
export const FETCH_CARRIER_CONTACT_ADDRESSES_START = 'FETCH_CARRIER_CONTACT_ADDRESSES_START';
export const FETCH_CARRIER_CONTACT_ADDRESSES_SUCCESS = 'FETCH_CARRIER_CONTACT_ADDRESSES_SUCCESS';
export const FETCH_CARRIER_CONTACT_ADDRESSES_ERROR = 'FETCH_CARRIER_CONTACT_ADDRESSES_ERROR';

export const CREATE_CARRIER_CONTACT_ADDRESS_START = 'CREATE_CARRIER_CONTACT_ADDRESS_START';
export const CREATE_CARRIER_CONTACT_ADDRESS_SUCCESS = 'CREATE_CARRIER_CONTACT_ADDRESS_SUCCESS';
export const CREATE_CARRIER_CONTACT_ADDRESS_ERROR = 'CREATE_CARRIER_CONTACT_ADDRESS_ERROR';

export const UPDATE_CARRIER_CONTACT_ADDRESS_START = 'UPDATE_CARRIER_CONTACT_ADDRESS_START';
export const UPDATE_CARRIER_CONTACT_ADDRESS_SUCCESS = 'UPDATE_CARRIER_CONTACT_ADDRESS_SUCCESS';
export const UPDATE_CARRIER_CONTACT_ADDRESS_ERROR = 'UPDATE_CARRIER_CONTACT_ADDRESS_ERROR';

export const DELETE_CARRIER_CONTACT_ADDRESS_START = 'DELETE_CARRIER_CONTACT_ADDRESS_START';
export const DELETE_CARRIER_CONTACT_ADDRESS_SUCCESS = 'DELETE_CARRIER_CONTACT_ADDRESS_SUCCESS';
export const DELETE_CARRIER_CONTACT_ADDRESS_ERROR = 'DELETE_CARRIER_CONTACT_ADDRESS_ERROR';

// Carrier Contact Emails Constants
export const FETCH_CARRIER_CONTACT_EMAILS_START = 'FETCH_CARRIER_CONTACT_EMAILS_START';
export const FETCH_CARRIER_CONTACT_EMAILS_SUCCESS = 'FETCH_CARRIER_CONTACT_EMAILS_SUCCESS';
export const FETCH_CARRIER_CONTACT_EMAILS_ERROR = 'FETCH_CARRIER_CONTACT_EMAILS_ERROR';

export const CREATE_CARRIER_CONTACT_EMAIL_START = 'CREATE_CARRIER_CONTACT_EMAIL_START';
export const CREATE_CARRIER_CONTACT_EMAIL_SUCCESS = 'CREATE_CARRIER_CONTACT_EMAIL_SUCCESS';
export const CREATE_CARRIER_CONTACT_EMAIL_ERROR = 'CREATE_CARRIER_CONTACT_EMAIL_ERROR';

export const UPDATE_CARRIER_CONTACT_EMAIL_START = 'UPDATE_CARRIER_CONTACT_EMAIL_START';
export const UPDATE_CARRIER_CONTACT_EMAIL_SUCCESS = 'UPDATE_CARRIER_CONTACT_EMAIL_SUCCESS';
export const UPDATE_CARRIER_CONTACT_EMAIL_ERROR = 'UPDATE_CARRIER_CONTACT_EMAIL_ERROR';

export const DELETE_CARRIER_CONTACT_EMAIL_START = 'DELETE_CARRIER_CONTACT_EMAIL_START';
export const DELETE_CARRIER_CONTACT_EMAIL_SUCCESS = 'DELETE_CARRIER_CONTACT_EMAIL_SUCCESS';
export const DELETE_CARRIER_CONTACT_EMAIL_ERROR = 'DELETE_CARRIER_CONTACT_EMAIL_ERROR';

// Carrier Contact Phones Constants
export const FETCH_CARRIER_CONTACT_PHONES_START = 'FETCH_CARRIER_CONTACT_PHONES_START';
export const FETCH_CARRIER_CONTACT_PHONES_SUCCESS = 'FETCH_CARRIER_CONTACT_PHONES_SUCCESS';
export const FETCH_CARRIER_CONTACT_PHONES_ERROR = 'FETCH_CARRIER_CONTACT_PHONES_ERROR';

export const CREATE_CARRIER_CONTACT_PHONE_START = 'CREATE_CARRIER_CONTACT_PHONE_START';
export const CREATE_CARRIER_CONTACT_PHONE_SUCCESS = 'CREATE_CARRIER_CONTACT_PHONE_SUCCESS';
export const CREATE_CARRIER_CONTACT_PHONE_ERROR = 'CREATE_CARRIER_CONTACT_PHONE_ERROR';

export const UPDATE_CARRIER_CONTACT_PHONE_START = 'UPDATE_CARRIER_CONTACT_PHONE_START';
export const UPDATE_CARRIER_CONTACT_PHONE_SUCCESS = 'UPDATE_CARRIER_CONTACT_PHONE_SUCCESS';
export const UPDATE_CARRIER_CONTACT_PHONE_ERROR = 'UPDATE_CARRIER_CONTACT_PHONE_ERROR';

export const DELETE_CARRIER_CONTACT_PHONE_START = 'DELETE_CARRIER_CONTACT_PHONE_START';
export const DELETE_CARRIER_CONTACT_PHONE_SUCCESS = 'DELETE_CARRIER_CONTACT_PHONE_SUCCESS';
export const DELETE_CARRIER_CONTACT_PHONE_ERROR = 'DELETE_CARRIER_CONTACT_PHONE_ERROR';


// Carrier Capacity
export const FETCH_CARRIER_KNOWN_LANES_START = 'FETCH_CARRIER_KNOWN_LANES_START';
export const FETCH_CARRIER_KNOWN_LANES_SUCCESS = 'FETCH_CARRIER_KNOWN_LANES_SUCCESS';
export const FETCH_CARRIER_KNOWN_LANES_ERROR = 'FETCH_CARRIER_KNOWN_LANES_ERROR';

export const DELETE_CARRIER_KNOWN_LANE_START = 'DELETE_CARRIER_KNOWN_LANE_START';
export const DELETE_CARRIER_KNOWN_LANE_SUCCESS = 'DELETE_CARRIER_KNOWN_LANE_SUCCESS';
export const DELETE_CARRIER_KNOWN_LANE_ERROR = 'DELETE_CARRIER_KNOWN_LANE_ERROR';

export const CREATE_CARRIER_KNOWN_LANE_START = 'CREATE_CARRIER_KNOWN_LANE_START';
export const CREATE_CARRIER_KNOWN_LANE_SUCCESS = 'CREATE_CARRIER_KNOWN_LANE_SUCCESS';
export const CREATE_CARRIER_KNOWN_LANE_ERROR = 'CREATE_CARRIER_KNOWN_LANE_SUCCESS';

export const UPDATE_CARRIER_KNOWN_LANE_START = 'UPDATE_CARRIER_KNOWN_LANE_START';
export const UPDATE_CARRIER_KNOWN_LANE_SUCCESS = 'UPDATE_CARRIER_KNOWN_LANE_SUCCESS';
export const UPDATE_CARRIER_KNOWN_LANE_ERROR = 'UPDATE_CARRIER_KNOWN_LANE_SUCCESS';

export const FETCH_CARRIER_KNOWN_LANE_MODS_START = 'FETCH_CARRIER_KNOWN_LANES_MODE_START';
export const FETCH_CARRIER_KNOWN_LANE_MODS_SUCCESS = 'FETCH_CARRIER_KNOWN_LANES_MODE_SUCCESS';
export const FETCH_CARRIER_KNOWN_LANE_MODS_ERROR = 'FETCH_CARRIER_KNOWN_LANES_MODE_ERROR';

export const FETCH_CURRENT_USER_KNOWN_LANE_MOD_START = 'FETCH_CURRENT_USER_KNOWN_LANE_MOD_START';
export const FETCH_CURRENT_USER_KNOWN_LANE_MOD_SUCCESS = 'FETCH_CURRENT_USER_KNOWN_LANE_MOD_SUCCESS';
export const FETCH_CURRENT_USER_KNOWN_LANE_MOD_ERROR = 'FETCH_CURRENT_USER_KNOWN_LANE_MOD_ERROR';


//Shipment
export const FETCH_SHIPMENT_DETAILS_START = 'FETCH_SHIPMENT_DETAILS_START';
export const FETCH_SHIPMENT_DETAILS_SUCCESS = 'FETCH_SHIPMENT_DETAILS_SUCCESS';
export const FETCH_SHIPMENT_DETAILS_ERROR = 'FETCH_SHIPMENT_DETAILS_ERROR';

//Location
export const FETCH_LOCATION_AUTOCOMPLETE_OPTS_START = 'FETCH_LOCATION_AUTOCOMPLETE_OPTS_START';
export const FETCH_LOCATION_AUTOCOMPLETE_OPTS_SUCCESS = 'FETCH_LOCATION_AUTOCOMPLETE_OPTS_SUCCESS';
export const FETCH_LOCATION_AUTOCOMPLETE_OPTS_ERROR = 'FETCH_LOCATION_AUTOCOMPLETE_OPTS_ERROR';

//Carrier List Options
export const FETCH_CARRIER_LIST_OPTIONS_START = 'FETCH_CARRIER_LIST_OPTIONS_START';
export const FETCH_CARRIER_LIST_OPTIONS_SUCCESS = 'FETCH_CARRIER_LIST_OPTIONS_SUCCESS';
export const FETCH_CARRIER_LIST_OPTIONS_ERROR = 'FETCH_CARRIER_LIST_OPTIONS_ERROR';

export const RECEIVE_CARRIER_NOTIFICATIONS_REALTIME = 'RECEIVE_CARRIER_NOTIFICATIONS_REALTIME';