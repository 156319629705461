import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ComponentType,
  FC,
} from "react";
import axios, { AxiosInstance } from "axios";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { toast, ToastContainer } from "react-toastify";
import styles from "./withErrorHandle.module.scss";

const withErrorHandler = <P extends object>(
  WrappedComponent: ComponentType<P>,
  axiosInstance: AxiosInstance
) => {
  const WithErrorHandler: FC<P> = (props) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [error, setError] = useState<boolean>(false);

    const user = useSelector((state: RootState) => state.oidc.user);

    const reqInterceptor = useMemo(() => {
      return (
        axiosInstance.interceptors.request.use(
          (req) => {
            if (req.headers) {
              req.headers.Authorization =
                req.headers.Authorization ?? `Bearer ${user?.access_token}`;
            }

            setError(false);
            return req;
          },
          (error) => {
            if (!(error instanceof Error)) {
              return Promise.reject(new Error('An unknown error occurred'));
            }
            return Promise.reject(error);
          }
        )
      )
    }, [user?.access_token])

    useEffect(() => {
      axiosInstance.defaults.baseURL = process.env.REACT_APP_API_URL;

      const resInterceptor = axiosInstance.interceptors.response.use(
        (res) => res,
        (error) => {
          // Ignore cancellation errors
          if (axios.isCancel(error)) {
            return null;
          }

          if (error.response) {
            const status = error.response.status;
            const message = error.response.data.message;

            switch (status) {
              case 400:
                setErrorMessage(message || "Bad Request");
                break;
              case 401:
                setErrorMessage("Not Authorized");
                break;
              case 404:
                setErrorMessage("Not Found");
                break;
              case 500:
                setErrorMessage(message || "Internal Server Error");
                break;
              case 504:
                setErrorMessage("Gateway Timeout");
                break;
              default:
                setErrorMessage("Something went wrong");
                break;
            }
            setError(true);
          } else {
            setErrorMessage(error.message || "Something went wrong");
            setError(true);
          }


          if (!(error instanceof Error)) {
            return Promise.reject(new Error('An unknown error occurred'));
          }
          return Promise.reject(error);
        }
      );

      return () => {
        axiosInstance.interceptors.request.eject(reqInterceptor);
        axiosInstance.interceptors.response.eject(resInterceptor);
      };
    }, [reqInterceptor, user?.access_token]);

    const handleClose = () => {
      setError(false);
      setErrorMessage(null);
    };

    const notify = useCallback(() => {
      if (error && errorMessage) {
        toast.error(
          <>
            <h3 className={styles.NotificationHeader}>Error</h3>
            <p className={styles.WrapErrorList}>{errorMessage}</p>
          </>,
          { autoClose: 10000, onClose: handleClose }
        );
      }
    }, [error, errorMessage]);

    useEffect(() => {
      notify();
    }, [notify]);

    return (
      <>
        <ToastContainer
          newestOnTop
          toastClassName={styles.NotificationToast}
          progressClassName={styles.NotificationProgressBar}
        />
        <WrappedComponent {...(props)} />
      </>
    );
  };

  return WithErrorHandler;
};

export default withErrorHandler;
