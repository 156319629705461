import {
  useState,
  useEffect,
  useRef,
  SyntheticEvent,
  useMemo,
  useCallback,
} from "react";
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { DropdownProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import { useAppDispatch } from "redux/store/hooks";

import CarrierFilterPopup from "./CarrierFilterPopup";
import CarrierFilterLabels from "./CarrierFilterLabels";
import PageHeader from "components/Navigation/PageHeader";
import { CarrierQuickCapacity } from "components/CarrierQuickCapacity/CarrierQuickCapacity";

import { carrierSortOptions } from "shared/constants";

import { RootState } from "redux/store/store";
import * as actions from "redux/store/actions";
import { actions as attributeActions } from "redux/carrier/carrierAttributesSlice";
import { inputDebounceDelay } from "hooks/constants";
import useCarriersFilter, {
  CarriersFilter,
  getBlankPopupFilter,
} from "hooks/useCarriersFilter";
import CarrierList from "components/CarrierList/List/CarrierList";
import { SelectedCarrier } from "models/SelectedCarrier";

import styles from "./Carrier.module.scss";

const Carriers = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [, setSearchParams] = useSearchParams();

  const attributesList = useSelector(
    (state: RootState) => state.carrierAttributes.list
  );
  const optionsData = useSelector(
    (state: RootState) => state.carriers.optionsData
  );
  const notifications = useSelector(
    (state: RootState) => state.carriers.notifications
  );

  const [isQuickCapacityOpen, setIsQuickCapacityOpen] =
    useState<boolean>(false);
  const [selectedCarrier, setSelectedCarrier] =
    useState<SelectedCarrier | null>(null);
  const [cancelRequest, setCancelRequest] = useState<boolean>(false);
  const [carriersFilter, setCarriersFilter] = useCarriersFilter(inputRef);

  useEffect(() => {
    dispatch(attributeActions.fetchCarrierAttributes());
    dispatch(actions.fetchCarrierListOptions());
  }, [dispatch]);

  useEffect(() => {
    if (notifications && notifications.length !== 0) {
      actions.receiveCarrierNotificationsRealtime(notifications);
    }
  }, [notifications]);

  const onSelectCarrier = (carrier: SelectedCarrier) => {
    setIsQuickCapacityOpen(true);
    setSelectedCarrier(carrier);
  };

  const handleSorting = (_: SyntheticEvent, { value }: DropdownProps) => {
    const [sortField, dir] = String(value).split(",");

    if (sortField && (dir === "ASC" || dir === "DESC")) {
      setCarriersFilter((currentFilter) => ({
        ...currentFilter,
        sort: { sortField, dir },
      }));
    }
  };

  const onFilterPopupChange = useCallback(
    (popup: CarriersFilter["popup"]) => {
      if (inputRef.current) {
        inputRef.current.value = "";
      }

      setCancelRequest(false);
      setCarriersFilter((currentFilter) => ({
        ...currentFilter,
        popup,
        search: { searchText: "", searchFields: "Name" },
      }));
      setSelectedCarrier(null);
      setIsQuickCapacityOpen(false);
    },
    [setCarriersFilter]
  );

  const debouncedFilterChange = useMemo(
    () =>
      debounce((searchText, searchFields) => {
        setCancelRequest(false);
        setCarriersFilter((currentFilter) => ({
          ...currentFilter,
          search: { searchText, searchFields },
        }));
        setSelectedCarrier(null);
        setIsQuickCapacityOpen(false);
      }, inputDebounceDelay),
    [setCarriersFilter]
  );

  const onSearch = useMemo(
    () => (searchText: string, searchFields: string) => {
      // cancelRequest gets passed to useGetCarriersInfinite to cancel in-flight requests as the user continues to type
      // Better UX and avoids user confusion about the results displayed
      setCancelRequest(true);
      debouncedFilterChange(searchText, searchFields);
    },
    [debouncedFilterChange]
  );

  return (
    <div className={styles.CarrierContainer}>
      <PageHeader
        inputRef={inputRef}
        onSearch={onSearch}
        carrierSearch={true}
        checkedButtons={location?.state?.searchFields || ""}
      />

      <CarrierFilterLabels
        carrierFilter={{
          ...carriersFilter,
          popup: carriersFilter.search.searchText
            ? getBlankPopupFilter()
            : carriersFilter.popup,
        }}
        onFilterPopupChange={onFilterPopupChange}
        clearWidgetValue={() => {
          setCarriersFilter((currentFilter) => ({
            ...currentFilter,
            popup: { ...currentFilter.popup, widget: "" },
          }));
          setSearchParams({});
        }}
        sorting={
          <div className={styles.Sort}>
            <span>
              Sort by{" "}
              <Dropdown
                inline
                options={carrierSortOptions}
                direction="left"
                value={`${carriersFilter.sort.sortField},${carriersFilter.sort.dir}`}
                onChange={handleSorting}
                clearable
              />
            </span>
          </div>
        }
        filter={
          <CarrierFilterPopup
            filterPopup={carriersFilter.popup}
            onFilterPopupChange={onFilterPopupChange}
            attributesList={attributesList}
            equipmentModeOptions={optionsData?.equipmentModeOptions}
            countryStates={optionsData?.countryStates}
            callActivityOptions={optionsData?.callActivityOptions}
          />
        }
      />

      {!isQuickCapacityOpen ? (
        <CarrierList
          onSelectCarrier={onSelectCarrier}
          selectedCarrier={selectedCarrier}
          carriersFilter={carriersFilter}
          cancelRequest={cancelRequest}
        />
      ) : null}

      {isQuickCapacityOpen && selectedCarrier ? (
        <CarrierQuickCapacity
          onSelectCarrier={onSelectCarrier}
          onQuickCapacityClose={() => setIsQuickCapacityOpen(false)}
          resetSelectedCarrier={() => setSelectedCarrier(null)}
          selectedCarrier={selectedCarrier}
          carriersFilter={carriersFilter}
          cancelRequest={cancelRequest}
        />
      ) : null}
    </div>
  );
};

export default Carriers;
