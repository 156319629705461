import axios from "axios";
import { generatePath, useNavigate } from "react-router-dom";
import React, { forwardRef, useCallback, useMemo, useState } from "react";
import cx from "classnames";

import { useAppSelector } from "redux/store/hooks";
import { RootState } from "redux/store/store";
import { CarriersListResponse } from "models/dto/CarriersListResponse";
import { SelectedCarrier } from "models/SelectedCarrier";
import routerList from "routes/routerList";
import { QueryGridResponse } from "models/QueryGridResponse";
import { InfiniteKeyedMutator } from "hooks/useGetCarriersInfinite";
import CarrierListRowCellsLarge from "./CarrierListRowCellsLarge";
import CarrierListRowCellsSmall from "./CarrierListRowCellsSmall";
import {
  formatContentSummaryForCarrierRow,
  defaultValue,
  formatDateForCarrierRow,
  formatPhoneForCarrierRow,
  getValueOrDefault,
  formatClassification,
} from "./utils/carrierListUtilities";

import styles from "../CarrierList.module.scss";

type CarrierListRowProps = {
  selectedCarrier: SelectedCarrier | null;
  carrier: CarriersListResponse;
  onRowClick: () => void;
  isFullSize: boolean;
  reloadCarriers: InfiniteKeyedMutator<
    QueryGridResponse<CarriersListResponse>[]
  >;
};

export type CarrierListRowCellsSizeProps = {
  carrier: CarriersListResponse;
  isOwnedByCurrentUser: boolean;
  handleCarrierEdit: (event: React.MouseEvent) => void;
  handleCarrierStar: (event: React.MouseEvent) => void;
  cellValues: {
    carrierName: string;
    carrierOwner: string;
    mcNumber: string;
    dotNumber: string;
    classification: string;
    phone: string;
    email: string;
    address: string;
    lastActivityTime: string;
    lastActivitySummary: string;
    lastActivityNote: string;
    matchesCapacities: string;
    unseenNotificationCount: string;
  };
  isLoadingStarredStatus: boolean;
};

const CarrierListRow = forwardRef<HTMLTableRowElement, CarrierListRowProps>(
  (props, ref) => {
    const navigate = useNavigate();
    const user = useAppSelector((state: RootState) => state.oidc.user);
    const userFullName = user
      ? `${user.profile.firstName} ${user.profile.lastName}`
      : "";
    const isSelectedCarrier = props.selectedCarrier?.id === props.carrier.id;
    const isOwnedByCurrentUser = props.carrier.owner?.name === userFullName;

    const [isLoadingStarredStatus, setIsLoadingStarredStatus] =
      useState<boolean>(false);

    const {
      reloadCarriers,
      carrier: { id: carrierId },
    } = props;

    // When the user stars/unstars a carrier, optimistically show the star change while the updated carrier list is being revalidated (better UX)
    const optimisticData = useCallback(
      (
        _?: QueryGridResponse<CarriersListResponse>[],
        displayedData?: QueryGridResponse<CarriersListResponse>[]
      ) => {
        if (!displayedData) {
          return [];
        }

        return displayedData.map((carrierResponse) => {
          const foundCarrier = carrierResponse.entities.find(
            (carrier) => carrier.id === carrierId
          );

          return foundCarrier
            ? {
                ...carrierResponse,
                entities: carrierResponse.entities.map((carrier) =>
                  carrier.id === carrierId
                    ? {
                        ...carrier,
                        isStarredByCurrentUser: !carrier.isStarredByCurrentUser,
                      }
                    : carrier
                ),
              }
            : carrierResponse;
        });
      },
      [carrierId]
    );

    const handleCarrierEdit = useCallback(
      (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        navigate(generatePath(routerList.carriers.details, { carrierId }));
      },
      [carrierId, navigate]
    );

    const handleCarrierStar = useCallback(
      (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();

        setIsLoadingStarredStatus(true);

        axios
          .post(`/api/carriers/${carrierId}/star`, { carrierId })
          .then(() => {
            reloadCarriers(undefined, {
              rollbackOnError: true,
              populateCache: false,
              optimisticData,
            });
          })
          .finally(() => setIsLoadingStarredStatus(false));
      },
      [carrierId, reloadCarriers, optimisticData]
    );

    const cellValues = useMemo(() => {
      const carrierName = getValueOrDefault(props.carrier.name);
      const carrierOwner = getValueOrDefault(props.carrier.owner?.name);
      const mcNumber = getValueOrDefault(props.carrier.mcNumber);
      const dotNumber = getValueOrDefault(props.carrier.dotNumber);
      const classification = formatClassification(
        props.carrier.classification
      );
      const phone = props.carrier.phone
        ? formatPhoneForCarrierRow(props.carrier.phone) || defaultValue
        : defaultValue;
      const email = getValueOrDefault(props.carrier.email);
      const address = getValueOrDefault(props.carrier.address);
      const lastActivityTime = props.carrier.lastActivity?.createdAt
        ? formatDateForCarrierRow(props.carrier.lastActivity.createdAt)
        : defaultValue;
      const lastActivitySummary = props.carrier.lastActivity
        ? formatContentSummaryForCarrierRow(props.carrier.lastActivity) ||
          defaultValue
        : defaultValue;
      const lastActivityNote = getValueOrDefault(
        props.carrier.lastActivity?.notes
      );
      const matchesCapacities = `${props.carrier.totalActiveMatches ?? 0}/${props.carrier.totalActiveCapacities ?? 0}`;
      const unseenNotificationCount =
        props.carrier.unseenNotificationsCount > 99
          ? "99+"
          : props.carrier.unseenNotificationsCount.toString();

      return {
        carrierName,
        carrierOwner,
        mcNumber,
        dotNumber,
        classification,
        phone,
        email,
        address,
        lastActivityTime,
        lastActivitySummary,
        lastActivityNote,
        matchesCapacities,
        unseenNotificationCount,
      };
    }, [props.carrier]);

    const cellProps: CarrierListRowCellsSizeProps = {
      carrier: props.carrier,
      isOwnedByCurrentUser,
      handleCarrierEdit,
      handleCarrierStar,
      cellValues,
      isLoadingStarredStatus,
    };

    return (
      <tr
        ref={isSelectedCarrier ? ref : null}
        onClick={props.onRowClick}
        className={cx(isSelectedCarrier && styles.activeRow)}
      >
        {props.isFullSize ? (
          <CarrierListRowCellsLarge {...cellProps} />
        ) : (
          <CarrierListRowCellsSmall {...cellProps} />
        )}
      </tr>
    );
  }
);

export default CarrierListRow;
