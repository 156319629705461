import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { User } from "oidc-client";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "redux/store/hooks";
import { RootState } from "redux/store/store";
import { GetCarriersPageQuery } from "models/dto/GetCarriersPageQuery";
import { GetPageQuery } from "models/dto/GetPageQuery";
import { SearchCarriersPageQuery } from "models/dto/SearchCarriersPageQuery";

// search takes precedence over popup
// Both use sort when making API request

export type CarriersFilter = {
  sort: Pick<GetPageQuery, "sortField" | "dir">;
  search: Omit<SearchCarriersPageQuery, keyof GetPageQuery>;
  popup: Omit<GetCarriersPageQuery, keyof GetPageQuery>;
};

export const getDefaultCarriersFilter = (): CarriersFilter => ({
  sort: {
    sortField: "name",
    dir: "ASC",
  },
  search: {
    searchFields: "",
    searchText: "",
  },
  popup: {
    shipmentId: "",
    origin: "",
    originMiles: "",
    destination: "",
    destinationMiles: "",
    domicileState: "",
    isOem: false,
    hasActiveCapacity: false,
    hasActivePreferredLanes: false,
    pickupServiceAreas: "",
    deliveryServiceAreas: "",
    equipmentTypes: "",
    lastActivityDate: "",
    modes: "",
    showCreated: true,
    showActive: true,
    showInactive: false,
    myCarriers: false,
    myStarredCarriers: false,
    name: "",
    attributes: [],
    callActivity: "",
    widget: "",
  },
});

export const getBlankPopupFilter = (): CarriersFilter["popup"] => ({
  ...getDefaultCarriersFilter().popup,
  showCreated: false,
  showActive: false,
});

const getLocalStorageKey = (user?: User) => {
  const firstName = user?.profile?.firstName || "";
  const lastName = user?.profile?.lastName || "";

  return `${firstName}_${lastName}_profiles-filter`;
};

const useCarriersFilter = (
  inputRef: RefObject<HTMLInputElement>
): [CarriersFilter, Dispatch<SetStateAction<CarriersFilter>>] => {
  const location = useLocation();
  const user = useAppSelector((state: RootState) => state.oidc.user);

  const [carriersFilter, setCarriersFilter] = useState<CarriersFilter>(() => {
    const defaultFilter = getDefaultCarriersFilter();

    const urlParams = new URLSearchParams(window.location.search);

    const storedData = localStorage.getItem(getLocalStorageKey(user));

    let initialFilter: CarriersFilter = storedData
      ? JSON.parse(storedData)
      : defaultFilter;

    // Check if the stored filter is valid
    Object.keys(initialFilter).forEach((key) => {
      if (!defaultFilter[key as keyof CarriersFilter]) {
        initialFilter = defaultFilter;
      }
    });

    // Always reset searchFilter on page load
    initialFilter.search = { ...defaultFilter.search };

    // If redirected from the dashboard widgets then initiate a search for that widget
    const widget = urlParams.get("widget");

    if (widget) {
      initialFilter.popup.widget = widget;

      switch (widget) {
        case "MyCarriers":
          initialFilter.sort = {
            sortField: "lastActivity",
            dir: "DESC",
          };
          break;
        case "MyMatches":
          initialFilter.sort = {
            sortField: "matches",
            dir: "DESC",
          };
          break;
      }
    }

    return initialFilter;
  });

  // Save all filter changes to local storage
  useEffect(() => {
    const filterString = JSON.stringify(carriersFilter);

    if (user) {
      localStorage.setItem(getLocalStorageKey(user), filterString);
    }
  }, [carriersFilter, user]);

  // If redirected from edit carrier page search, then initiate a search with that query
  // Also replace the state with an empty object to prevent the search from being triggered again on refresh
  const { state, pathname } = location;
  useEffect(() => {
    if (state?.searchText) {
      setCarriersFilter((previousFilter) => ({
        ...previousFilter,
        search: {
          searchText: state.searchText,
          searchFields: state.searchFields,
        },
      }));

      if (inputRef.current) {
        inputRef.current.value = state.searchText;
      }

      window.history.replaceState({}, "", pathname);
    }
  }, [state, pathname, inputRef]);

  return [carriersFilter, setCarriersFilter];
};

export default useCarriersFilter;
