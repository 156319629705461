import {
  CarrierFilterLabelList,
  TRIGGER_TYPES,
} from "./CarrierFilterLabelList";
import { getModesArray } from "shared/utils/modesHelper";
import "./CarrierFilterLabels.scss";
import styles from "components/Navigation/PageHeader/PageHeader.module.scss";
import { CarriersFilter } from "hooks/useCarriersFilter";

interface CarrierFilterLabelsProps {
  carrierFilter: CarriersFilter;
  onFilterPopupChange: (updatedFilter: CarriersFilter["popup"]) => void;
  clearWidgetValue: () => void;
  filter?: React.ReactNode;
  sorting?: React.ReactNode;
}

const CarrierFilterLabels = ({
  carrierFilter,
  onFilterPopupChange,
  clearWidgetValue,
  sorting,
  filter,
}: CarrierFilterLabelsProps) => {
  const modes = getModesArray(carrierFilter.popup.modes);
  const pickupServiceAreas = carrierFilter.popup.pickupServiceAreas?.length
    ? carrierFilter.popup.pickupServiceAreas?.split(",")
    : [];
  const deliveryServiceAreas = carrierFilter.popup.deliveryServiceAreas?.length
    ? carrierFilter.popup.deliveryServiceAreas?.split(",")
    : [];
  const equipmentTypes = carrierFilter.popup.equipmentTypes?.split(",");

  const handleClearMode = (value: string) => {
    onFilterPopupChange({
      ...carrierFilter.popup,
      modes: modes.filter((mode) => mode !== value).join(","),
    });
  };

  const handleClearPickupArea = (value: string) => {
    onFilterPopupChange({
      ...carrierFilter.popup,
      pickupServiceAreas: pickupServiceAreas
        .filter((pickupServiceArea: string) => pickupServiceArea !== value)
        .join(","),
    });
  };

  const handleClearDeliverypArea = (value: string) => {
    onFilterPopupChange({
      ...carrierFilter.popup,
      deliveryServiceAreas: deliveryServiceAreas
        .filter((deliveryServiceArea: string) => deliveryServiceArea !== value)
        .join(","),
    });
  };

  const handleClearEquipmentType = (value: string) => {
    onFilterPopupChange({
      ...carrierFilter.popup,
      equipmentTypes: equipmentTypes
        .filter((equipmentType: string) => equipmentType !== value)
        .join(","),
    });
  };

  const handleClearPopupField = (fieldName: string) => {
    if (fieldName === "origin" || fieldName === "destination") {
      onFilterPopupChange({
        ...carrierFilter.popup,
        [fieldName]: "",
        [`${fieldName}Miles`]: "",
      });
    } else if (fieldName === "name") {
      onFilterPopupChange({
        ...carrierFilter.popup,
        name: "",
      });
    } else {
      onFilterPopupChange({ ...carrierFilter.popup, [fieldName]: "" });
    }
  };

  const handleClearWidgetValue = () => {
    clearWidgetValue();
  };

  const handleCheckbox = (checkboxName: string) => {
    onFilterPopupChange({
      ...carrierFilter.popup,
      [checkboxName]: false,
    });
  };

  const handleClearAttribute = (attrId: unknown) => {
    onFilterPopupChange({
      ...carrierFilter.popup,
      attributes: carrierFilter.popup.attributes.filter(
        (el) => el.profilesAttributeId !== attrId
      ),
    });
  };

  const handleClearTriggerClick = ({
    fieldType,
    fieldName,
  }: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fieldType: TRIGGER_TYPES;
    fieldName: string;
  }) => {
    switch (fieldType) {
      case TRIGGER_TYPES.POPUP_FIELD:
        handleClearPopupField(fieldName);
        break;
      case TRIGGER_TYPES.CHECKBOX_FIELD:
        handleCheckbox(fieldName);
        break;
      case TRIGGER_TYPES.MODE_FIELD:
        handleClearMode(fieldName);
        break;
      case TRIGGER_TYPES.PICKUP_AREA_FIELD:
        handleClearPickupArea(fieldName);
        break;
      case TRIGGER_TYPES.DELIVERY_AREA_FIELD:
        handleClearDeliverypArea(fieldName);
        break;
      case TRIGGER_TYPES.EQUIPMENT_TYPE_FIELD:
        handleClearEquipmentType(fieldName);
        break;
      case TRIGGER_TYPES.WIDGET_FIELD:
        handleClearWidgetValue();
        break;
      case TRIGGER_TYPES.ATTRIBUTE_FIELD:
        handleClearAttribute(fieldName);
        break;
      default:
        throw new Error(`${fieldType} is not defined`);
    }
  };

  return (
    <div className="carrier-label-container">
      <CarrierFilterLabelList
        carrierFilter={carrierFilter}
        handleClearTriggerClick={handleClearTriggerClick}
        modes={modes}
        pickupServiceAreas={pickupServiceAreas}
        deliveryServiceAreas={deliveryServiceAreas}
        equipmentTypes={equipmentTypes}
      />

      {filter ? (
        <div className={styles.carrierFilterSection}>
          {sorting}
          {filter}
        </div>
      ) : null}
    </div>
  );
};

export default CarrierFilterLabels;
