import axios from "axios";

import * as actionTypes from "../constants/actionTypes";

const fetchCarrierNotesSuccess = (carrier) => {
  return {
    type: actionTypes.FETCH_CARRIER_NOTES_SUCCESS,
    carrier: carrier,
  };
};

export const fetchCarrierNotes = (carrierId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/carriers/${carrierId}`)
        .then((response) => {
          dispatch(fetchCarrierNotesSuccess(response.data));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const fetchSingleCarrierStart = () => {
  return { type: actionTypes.FETCH_SINGLE_CARRIER_START };
};
const fetchSingleCarrierSuccess = (carrier) => {
  return {
    type: actionTypes.FETCH_SINGLE_CARRIER_SUCCESS,
    carrier: carrier,
  };
};
const fetchSingleCarrierError = () => {
  return { type: actionTypes.FETCH_SINGLE_CARRIER_START };
};

export const fetchSingleCarrier = (carrierId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchSingleCarrierStart());
      axios
        .get(`/api/carriers/${carrierId}`)
        .then((response) => {
          dispatch(fetchSingleCarrierSuccess(response.data));
          resolve(response);
        })
        .catch((error) => {
          dispatch(fetchSingleCarrierError());
          reject(error);
        });
    });
  };
};

const createCarrierStart = () => {
  return { type: actionTypes.CREATE_CARRIER_START };
};
const createCarrierSuccess = () => {
  return { type: actionTypes.CREATE_CARRIER_SUCCESS };
};
const createCarrierError = () => {
  return { type: actionTypes.CREATE_CARRIER_ERROR };
};

export const createCarrier = (obj) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(createCarrierStart());

      axios
        .post("/api/carriers", obj)
        .then((response) => {
          dispatch(createCarrierSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(createCarrierError());
          reject(error);
        });
    });
  };
};

const updateCarrierStart = () => {
  return { type: actionTypes.UPDATE_CARRIER_START };
};
const updateCarrierSuccess = () => {
  return { type: actionTypes.UPDATE_CARRIER_SUCCESS };
};
const updateCarrierError = () => {
  return { type: actionTypes.UPDATE_CARRIER_ERROR };
};

export const updateCarrier = (obj) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(updateCarrierStart());

      axios
        .put("/api/carriers", obj)
        .then((response) => {
          dispatch(updateCarrierSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(updateCarrierError());
          reject(error);
        });
    });
  };
};

const fetchShipmentDetailsStart = (shipmentId) => {
  return {
    type: actionTypes.FETCH_SHIPMENT_DETAILS_START,
    shipmentId: shipmentId,
  };
};

const fetchShipmentDetailsSuccess = (shipmentDetails) => {
  return {
    type: actionTypes.FETCH_SHIPMENT_DETAILS_SUCCESS,
    shipmentDetails: shipmentDetails,
  };
};

const fetchShipmentDetailsError = () => {
  return { type: actionTypes.FETCH_SHIPMENT_DETAILS_ERROR };
};

export const fetchShipmentDetails = (shipmentId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchShipmentDetailsStart(shipmentId));
      axios
        .get(`/api/shipments/${shipmentId}`)
        .then((response) => {
          dispatch(fetchShipmentDetailsSuccess(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(fetchShipmentDetailsError());
          reject(error);
        });
    });
  };
};

const uploadCarriersFromCsvStart = () => {
  return { type: actionTypes.UPLOAD_FROM_CSV_START };
};
const uploadCarriersFromCsvSuccess = () => {
  return { type: actionTypes.UPLOAD_FROM_CSV_SUCCESS };
};
const uploadCarriersFromCsvError = () => {
  return { type: actionTypes.UPLOAD_FROM_CSV_ERROR };
};

export const uploadCarriersFromCsv = (formData) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(uploadCarriersFromCsvStart());
      axios
        .post(`/api/carriers/uploadCsv`, formData)
        .then((res) => {
          dispatch(uploadCarriersFromCsvSuccess());
          resolve && resolve(res);
        })
        .catch(() => {
          dispatch(uploadCarriersFromCsvError());
          reject && reject();
        });
    });
  };
};

const uploadCarrierImageStart = () => {
  return { type: actionTypes.UPLOAD_IMAGE_START };
};
const uploadCarrierImageSuccess = () => {
  return { type: actionTypes.UPLOAD_IMAGE_SUCCESS };
};
const uploadCarrierImageError = () => {
  return { type: actionTypes.UPLOAD_IMAGE_ERROR };
};

export const uploadCarrierImage = (carrierId, formData, refreshCallback) => {
  return (dispatch) => {
    dispatch(uploadCarrierImageStart());
    axios
      .post(`/api/carriers/${carrierId}/images`, formData)
      .then(() => {
        dispatch(uploadCarrierImageSuccess());
        refreshCallback();
      })
      .catch(() => dispatch(uploadCarrierImageError()));
  };
};

const deleteCarrierImageStart = () => {
  return { type: actionTypes.DELETE_IMAGE_START };
};
const deleteCarrierImageSuccess = () => {
  return { type: actionTypes.DELETE_IMAGE_SUCCESS };
};
const deleteCarrierImageError = () => {
  return { type: actionTypes.DELETE_IMAGE_ERROR };
};

export const deleteCarrierImage = (carrierId, refreshCallback) => {
  return (dispatch) => {
    dispatch(deleteCarrierImageStart());
    axios
      .delete(`/api/carriers/${carrierId}/images`)
      .then(() => {
        dispatch(deleteCarrierImageSuccess());
        refreshCallback();
      })
      .catch(() => dispatch(deleteCarrierImageError()));
  };
};

const validateCarrierStart = () => {
  return { type: actionTypes.VALIDATE_CARRIER_START };
};
const validateCarrierSuccess = () => {
  return { type: actionTypes.VALIDATE_CARRIER_SUCCESS };
};
const validateCarrierError = () => {
  return { type: actionTypes.VALIDATE_CARRIER_ERROR };
};

export const validateCarrier = (carrier, carrierId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(validateCarrierStart());
      axios
        .post(`/api/carriers/validate/${carrierId}`, carrier)
        .then((response) => {
          dispatch(validateCarrierSuccess());
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(validateCarrierError());
          reject(error);
        });
    });
  };
};

/**
 * CARRIER LIST OPTIONS
 */
const fetchCarrierListOptionsStart = () => {
  return { type: actionTypes.FETCH_CARRIER_LIST_OPTIONS_START };
};
const fetchCarrierListOptionsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_CARRIER_LIST_OPTIONS_SUCCESS,
    payload: data,
  };
};
const fetchCarrierListOptionsError = (error) => {
  return { type: actionTypes.FETCH_CARRIER_LIST_OPTIONS_ERROR, payload: error };
};

export const fetchCarrierListOptions = () => (dispatch) => {
  dispatch(fetchCarrierListOptionsStart());
  return new Promise((_, reject) => {
    axios
      .get(`/api/carriers/options`)
      .then((response) => {
        const options = {
          ...response.data,
          countryStates:
            response.data?.countryStates?.map((el) => ({
              key: el,
              value: el,
              text: el,
            })) || [],
          callActivityOptions:
            response.data?.callActivityOptions?.map((el) => ({
              key: el,
              value: el,
              text: el,
            })) || [],
        };
        dispatch(fetchCarrierListOptionsSuccess(options));
      })
      .catch((error) => {
        dispatch(fetchCarrierListOptionsError(error));
        reject(error);
      });
  });
};

export const receiveCarrierNotificationsRealtime = (norifications) => {
  return {
    type: actionTypes.RECEIVE_CARRIER_NOTIFICATIONS_REALTIME,
    payload: norifications,
  };
};
