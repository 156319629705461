import { useEffect, useRef } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Arguments, MutatorCallback, MutatorOptions } from "swr";
import useSWRInfinite from "swr/infinite";

import { CarriersListResponse } from "models/dto/CarriersListResponse";
import { QueryGridResponse } from "models/QueryGridResponse";
import { CarriersFilter } from "./useCarriersFilter";

const pageSize = 25;

// SWR does not export these which is making it a PITA to type
type SWRInfiniteRevalidateFn<Data> = (data: Data, key: Arguments) => boolean;

interface SWRInfiniteMutatorOptions<Data, MutationData = Data>
  extends Omit<MutatorOptions<Data, MutationData>, "revalidate"> {
  revalidate?:
    | boolean
    | SWRInfiniteRevalidateFn<Data extends unknown[] ? Data[number] : never>;
}

export type InfiniteKeyedMutator<Data> = <MutationData = Data>(
  data?: Data | Promise<Data | undefined> | MutatorCallback<Data>,
  opts?: boolean | SWRInfiniteMutatorOptions<Data, MutationData>
) => Promise<Data | MutationData | undefined>;

const useGetCarriersInfinite = ({
  cancelRequest,
  carriersFilter: { sort, search, popup },
}: {
  cancelRequest?: boolean;
  carriersFilter: CarriersFilter;
}): {
  carriers: CarriersListResponse[];
  totalCarriers: number;
  isLoading: boolean;
  getNextPage: () => void;
  reloadCarriers: InfiniteKeyedMutator<
    QueryGridResponse<CarriersListResponse>[]
  >;
} => {
  const abortController = useRef<AbortController>();
  const isSearch = search.searchText.length > 0;
  const baseUrl = isSearch ? "/api/carriers/search" : "/api/carriers/list";

  useEffect(() => {
    if (cancelRequest && abortController.current) {
      abortController.current.abort();
    }
  }, [cancelRequest]);

  const getKey = (
    page: number,
    previousPageData: QueryGridResponse<CarriersListResponse>
  ) => {
    if (previousPageData && !previousPageData.entities.length) {
      return null;
    }

    const params = new URLSearchParams({
      ...sort,
      page: (page + 1).toString(),
      pageSize: pageSize.toString(),
      rest: JSON.stringify(isSearch ? search : popup),
    });

    return params.toString();
  };

  const swr = useSWRInfinite<
    QueryGridResponse<CarriersListResponse>,
    AxiosError
  >(
    getKey,
    (url: string) => {
      abortController.current = new AbortController();
      const urlSearchParams = new URLSearchParams(url);
      const page = Number(urlSearchParams.get("page"));
      const body = {
        pageSize,
        ...sort,
        ...(isSearch ? search : popup),
      };

      return axios
        .post<
          QueryGridResponse<CarriersListResponse>,
          AxiosResponse<QueryGridResponse<CarriersListResponse>>,
          typeof body & { page: number }
        >(baseUrl, { ...body, page }, { signal: abortController.current.signal })
        .then((response) => response.data);
    },
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  return {
    carriers: swr.data?.flatMap((data) => data.entities) ?? [],
    totalCarriers: swr.data?.[0].filteredCount ?? 0,
    isLoading:
      swr.isLoading ||
      (!swr.error && swr.size > 0 && !swr.data?.[swr.size - 1]),
    getNextPage: () => {
      swr.setSize(swr.size + 1);
    },
    reloadCarriers: swr.mutate,
  };
};

export default useGetCarriersInfinite;
